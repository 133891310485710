import React from 'react'
import "../styles/nav.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Facebook, Instagram, LinkedIn,X } from "@mui/icons-material";

const Footer = ({ spacing }) => {
  return (
    <div>
      <footer className="footer">
        <div className="contact-us">
          <h3>Contact Us</h3> 
          <p>1st Floor, Life Ministry Building,<br />Kilimani, Nairobi<br />Email: info@jazacapital.com<br />Phone: +254 740 934 171</p>
        </div>
        <div className="social-media">
          <h3>Follow Us</h3>
          <ul>
            <li><a href="#"><Facebook/></a></li>
            <li><a href="#"><Instagram/></a></li>
            <li><a href="#"><X/></a></li>
            <li><a href="#"><LinkedIn/></a></li>
          </ul>
        </div>
        
      </footer>
      <footer className="bg-body-tertiary text-center text-lg-start">
      <div className="text-center p-3" style={{ backgroundColor: '#008000', marginTop: spacing, color: 'white'}}>
        © 2024 Copyright:Jaza Capital Limited.
        <a className="text-center p-3" href="https://mdbootstrap.com/" style={{ color: 'white', textDecoration: 'none'}}>All Rights Reserved</a>
      </div>
    </footer>
    </div>
  )
}

export default Footer
