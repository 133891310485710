import React from "react";
import logo from "../assets/logo.png";
import office from "../assets/companyImage.png";
import "../styles/nav.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";
import credit from "../assets/credit.png";
import financedClient from "../assets/financed.png";
import instant from "../assets/instant.png";
import centers from "../assets/center.png";
import staff from "../assets/staff.png";
import agents from "../assets/agent.png";
import FloatingSocialIcons from "../component/FloatingSocialIcons";
import { Facebook, Instagram, LinkedIn, X } from "@mui/icons-material";
import Footer from "../component/Footer";
import USSDSection from '../component/USSDSection';
const Homepage = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top bg-light navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              id="MDB-logo"
              src={logo}
              alt="MDB Logo"
              draggable="false"
              height="50"
            />
            Jaza Capital LTD
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/">
                  <i className="fa fa-home fa-fw pe-2"></i>Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/About">
                  <i className="fa fa-info-circle pe-2"></i>About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/Services">
                  <i className="fa fa-filter pe-2"></i>Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/Contact">
                  <i className="fa fa-phone pe-2"></i>Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="hero">
        <h1>Welcome to Jaza Capital Limited</h1>
        <p>Empowering businesses through credit financing</p>
        <Link  className="learn-more"to="/Services">Our Services</Link>
      </section>
      <section class="p-5">
        <USSDSection/>
      </section>
      <section class="p-1">
        <div class="services-container">
          <div class="row justify-content-center text-center mb-2">
            <div class="col-lg-8 col-xl-7">
              <span class="text-muted">At a glance</span>
              <h1 class="display-7 ">Where We Stand</h1>
              <p class="lead display-10">
                At Jaza Capital Limited, we take pride in our commitment to
                uplifting small and medium-based businesses. Contributing to the
                creation of numerous jobs and the economic development of our
                communities.
              </p>
            </div>
          </div>

          <div class="row mt-5 justify-content-center">
            <div class="col-md-3 col-sm-6 mb-3 mb-md-0 text-center">
              <div class="mb-3">
                <img
                  src={financedClient}
                  alt="financed-client"
                  className="business-image"
                />
              </div>
              <h3>10K+</h3>
              <p>Financed Client Businesses</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-3 mb-md-0 text-center">
              <div class="mb-3">
                <img src={credit} alt="Credit" className="business-image" />
              </div>
              <h4>KES 500M+</h4>
              <p>Credit to businesses</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-3 mb-md-0 text-center">
              <div class="mb-3">
                <img
                  src={instant}
                  alt="Instant-Credit"
                  className="business-image"
                />
              </div>
              <h4>Instant Credit</h4>
              <p>Get instant credits</p>
            </div>
            <div class="col-md-3 col-sm-6 text-center">
              <div class="mb-3">
                <img src={centers} alt="Centers" className="business-image" />
              </div>
              <h4>15+ </h4>
              <p>Centers Operating in Kenya</p>
            </div>
            <div class="col-md-3 col-sm-6 text-center">
              <div class="mb-3">
                <img src={staff} alt="Staff" className="business-image" />
              </div>
              <h4>10+</h4>
              <p>HQ operations staff</p>
            </div>
            <div class="col-md-3 col-sm-6 text-center">
              <div class="mb-3">
                <img src={agents} alt="Agents" className="business-image" />
              </div>
              <h4>70+ </h4>
              <p>Branch agents</p>
            </div>
          </div>
        </div>
      </section>

      <section className="who-we-are">
        <div class="row justify-content-center text-center mb-3">
          <div class="col-lg-8 col-xl-7">
            <h2>Who We Are</h2>
            <p class="lead display-10">
              We are committed to empowering individuals and communities through
              innovative microfinance solutions. Our goal is to provide
              accessible financial services that drive growth and create
              opportunities for everyone.
            </p>
          </div>
        </div>
        <div className="mission-vision-container">
          <div className="mission-vision">
            <div className="mission">
              <h3>Our Mission</h3>
              <p>
                At Jaza Capital Limited, our mission is to provide unparalleled
                financial services and investment opportunities to help our
                clients achieve their financial goals. We are committed to
                maintaining the highest standards of integrity and
                professionalism in our relationship with you, our client.
              </p>
            </div>
            <div className="vision">
              <h3>Our Vision</h3>
              <p>
                Our vision is to be a leading financial institution recognized
                for our innovative solutions and exceptional client service. We
                strive to create a positive impact on the communities we serve
                and to be a trusted partner in your financial journey.
              </p>
            </div>
          </div>
          <div className="image-container">
            <img src={office} alt="Office meeting" className="office-image" />
          </div>
        </div>
      </section>

      

      <FloatingSocialIcons />
      <Footer />
    </div>
  );
};

export default Homepage;
