import React from 'react';
import logo from '../assets/logo.png';
import "../styles/nav.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top bg-light navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img id="MDB-logo" src={logo} alt="MDB Logo" draggable="false" height="50" />
            Jaza Capital LTD
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/"><i className="fa fa-home fa-fw pe-2"></i>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/About"><i className="fa fa-info-circle pe-2"></i>About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/Services"><i className="fa fa-filter pe-2"></i>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/Contact"><i className="fa fa-phone pe-2"></i>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
