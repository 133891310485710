import React from "react";
import { IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { AiFillTikTok } from "react-icons/ai";
import styled from "styled-components";

const SocialContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: #4CAF50; /* Green background */
  border-radius: 0 8px 8px 0;
  z-index: 1000;

  @media (max-width: 768px) {
    top: auto;
    bottom: 10px;
    left: 10px;
    transform: none;
    flex-direction: row;
    border-radius: 8px;
    background: #4CAF50;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: white; /* Initial icon color */

  &:hover {
    color: #4CAF50; /* Hover text color */
    background-color: white; /* Hover background color */
  }
`;

const FloatingSocialIcons = () => {
  return (
    <SocialContainer>
      <a href="https://www.linkedin.com/company/jaza-capital-limited/" target="_blank" rel="noopener noreferrer">
        <StyledIconButton aria-label="LinkedIn">
          <LinkedIn />
        </StyledIconButton>
      </a>
    </SocialContainer>
  );
};

export default FloatingSocialIcons;
