import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import grocery from '../assets/grocery.jpg'
import '../styles/about.css'
import FloatingSocialIcons from '../component/FloatingSocialIcons'

const Aboutpage = () => {
  return (
    <div>
        {/* <Navbar /> */}
        <div>
            <Navbar />
        </div>
        <div class= 'container'>
            <div class="container_content">
            <div class="container_content_inner">
            <div class="title">
            <h1>About Us </h1>
            </div>
            <div class="par">
            <p>
                Jaza Capital Limited is a premier financial services company with a rich history of providing top-notch financial solutions. 
                Our commitment to excellence and integrity has made us a trusted name in the industry.
            </p>
            </div>
            <div class="btns">
            {/* <button class='btns_more'> See more </button> */}
            </div>
            </div>
            </div>
            <div class="container_outer_img">
            <div class="img-inner">
            <img src={grocery}  alt="" class="container_img"/>
                </div>
                </div>
            </div>
            <div class="overlay">

        </div>
        <section class="py-5">
            <div className='history-container'>
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="text-center">
                            <span class="text-muted">Our Story</span>
                            <h2 class="display-6 fw-bold" style={{color:'#008000'}}>Our History</h2>
                            <p class="lead">Founded in 2021, Jaza Capital Limited is a dedicated microfinance institution 
                                that specializes in offering financial support to small and micro-entrepreneurs. 
                                Our team comprises seasoned professionals with extensive experience in microfinance 
                                and a deep understanding of the unique challenges faced by small business owners.
                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="history-container">
                <div class="row">
                    <div class="col-md-5 text-center align-middle"  >
                        <h2 class="display-6 fw-bold"  style={{color:'#008000'}}>Our Values</h2>
                    </div>
                    <div class="col-md-6 offset-md-1">
                        <p class="lead">At Jaza Capital Limited, our core values are Integrity, Excellence, and Innovation. These principles guide our actions and decision-making processes,
                            ensuring that we always put our clients' needs first.. </p>
                    </div>
                </div>
            </div>
        </section>
        <FloatingSocialIcons/>
        <Footer/>
      
    </div>
  )
}

export default Aboutpage
