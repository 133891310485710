import React from 'react'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'
import map from '../assets/map.png'
import '../styles/about.css'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import FloatingSocialIcons from '../component/FloatingSocialIcons'
import contactImage from '../assets/contactImage.png'
const Contactpage = () => {
  return (
    <div>
      <div>
        <Navbar/>

        <Container fluid className="p-5" style={{ backgroundColor: '#f8f9fa', marginTop: '40px'}}>
            <Container fluid>
                <Row className="mb-4 justify-content-center text-center">
                <Col md={8}>
                    <span class="text-muted">Let's Talk</span>
                    <h1 className="text-center">Contact Us</h1>
                    <div>
                        <img style={{maxHeight: '200px'}} src={contactImage} alt="contact-image" />
                    </div>
                </Col>
                </Row>
                <Row style={{marginTop: '50px'}}>
                <Col md={6}>
                    <h5>Address</h5>
                    <p>1st Floor, Life Ministry Building, Kilimani, Nairobi</p>
                    <h5>Call Us</h5>
                    <p> +254 103 227 047<br/>+254 740 934 171 <br/>  +254 711 315 178</p>
                    <h5>Email</h5>
                    <p> careers@jazacapital.co.ke <br/>info@jazacapital.co.ke</p>
                    <h5>Follow Us</h5>
                    <p>
                    <a href="#" className="btn btn-primary btn-sm me-2"><i className="fab fa-facebook-f"></i></a>
                    <a href="#" className="btn btn-info btn-sm me-2"><i className="fab fa-twitter"></i></a>
                    <a href="#" className="btn btn-secondary btn-sm"><i className="fab fa-linkedin-in"></i></a>
                    </p>
                    <h5>Location</h5>
                    <img src={map} alt="Map" className="img-fluid" />
                </Col>
                <Col md={6}>
                    <h5>Get in Touch</h5>
                    <Form action="https://formsubmit.co/fafdc3700d261eb39ec0389490d9e07a" method="POST" >
                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your full name" name='FullName' />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter your email" name='Email' />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={5} placeholder="Enter your message" name='Message'/>
                    </Form.Group>
                    <Button style={{backgroundColor:"#008000", borderColor:"#008000"}} type="submit">
                        Send Message
                    </Button>
                    </Form>
                </Col>
                </Row>
            </Container>
            </Container>
            <FloatingSocialIcons/>

        <Footer/>
      </div>
    </div>
  )
}

export default Contactpage
