import React from 'react';
import '../styles/USSDSection.css';
import logo from '../assets/logo.png'
import man from '../assets/man.png'

const USSDSection = () => {
  return (
    <div className="ussd-container">
      <div className="contentSection">
        <span
          style={{
            color: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%", // Ensures the flex container takes up the full width
          }}
        >
          Coming Soon
        </span>
        <h1 className="mainTitle">YOU WILL ENJOY SELF SERVICE.</h1>
        <h2 className="subtitle">MAMBO ITAKUWA ONE TOUCH</h2>
        <p className="description">
          You will be able to self service your loans without even contacting
          our customer support!
        </p>
        {/* <p className='description'>
          Did you know you can self service your loans without even contacting our customer support!
        </p> */}
        <div className="ussdCode">
          DIAL <span className="code">*###*##</span>
        </div>
        <div style={{ fontWeight: "bold", marginTop: "20px" }}>
          Simple. Transparent. Honest. FOR YOU.
        </div>
      </div>
      <div className="imageSection">
        <img
          src={man}
          alt="Person using mobile phone"
          className="personImage"
        />
      </div>
      <img src={logo} alt="Safaricom logo" className="ussd-logo" />
    </div>
  );
};

export default USSDSection;